import React from 'react';
import { PropTypes } from 'prop-types';
import Select, { components } from 'react-select';

import * as Constant from '../../../utils/constants';

import OneStarImage from '../../../images/StarImages/1Stars@1x.png';
import TwoStarImage from '../../../images/StarImages/2Stars@1x.png';
import ThreeStarImage from '../../../images/StarImages/3Stars@1x.png';
import FourStarImage from '../../../images/StarImages/4Stars@1x.png';
import FiveStarImage from '../../../images/StarImages/5Stars@1x.png';

import { SCREENER_TYPES } from '../../../utils/screenerConstants';
import * as utilityFunctions from '../../../utils/commonMethods';


export default class ScreenerFilters extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedBrandName: 'None',
			selectedBaseCurrency: 'None',
			selectedMorningStarCategory: 'None',
			screenerMasterData: {},
			morningStarBrandName: [],
			morningStarCategory: [],
			baseCurrency: [],
			starElement: 'None',
			brandNameElement: 'None',
			fundCategoryElement: 'None',
			resetTab: false
		};
	}

	componentDidMount() {
		if (
			this.props.screenerMasterData &&
			this.props.screenerMasterData.Columns &&
			this.props.screenerMasterData.Columns2 &&
			this.props.screenerMasterData.Columns3 //&&
		) {
			let optionsBaseCurrency = this.props.screenerMasterData && this.props.screenerMasterData.Columns && this.props.screenerMasterData.Columns.map(x => x);
			let optionsMorningStarCategory = this.props.screenerMasterData && this.props.screenerMasterData.Columns2 && this.props.screenerMasterData.Columns2.map(x => x);
			let optionsMorningStarBrandName = this.props.screenerMasterData && this.props.screenerMasterData.Columns3 && this.props.screenerMasterData.Columns3.map(x => x);

			optionsMorningStarBrandName = optionsMorningStarBrandName !== null ? optionsMorningStarBrandName.sort((a, b) => a.localeCompare(b)) : '';
			optionsMorningStarCategory = optionsMorningStarCategory !== null ? optionsMorningStarCategory.sort((a, b) => a.localeCompare(b)) : '';
			optionsBaseCurrency = optionsBaseCurrency !== null ? optionsBaseCurrency.sort((a, b) => a.localeCompare(b)) : '';

			this.setState({
				screenerMasterData: this.props.screenerMasterData,
				morningStarCategory: optionsMorningStarCategory,
				morningStarBrandName: optionsMorningStarBrandName,
				baseCurrency: optionsBaseCurrency
			});
		}


	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (
			nextProps.screenerMasterData &&
			nextProps.screenerMasterData.Columns !== this.props.screenerMasterData.Columns &&
			nextProps.screenerMasterData.Columns2 !== this.props.screenerMasterData.Columns2 &&
			nextProps.screenerMasterData.Columns3 !== this.props.screenerMasterData.Columns3 //&&
		) {
			let optionsBaseCurrency = nextProps.screenerMasterData && nextProps.screenerMasterData.Columns && nextProps.screenerMasterData.Columns.map(x => x);
			let optionsMorningStarCategory = nextProps.screenerMasterData && nextProps.screenerMasterData.Columns2 && nextProps.screenerMasterData.Columns2.map(x => x);
			let optionsMorningStarBrandName = nextProps.screenerMasterData && nextProps.screenerMasterData.Columns3 && nextProps.screenerMasterData.Columns3.map(x => x);

			optionsMorningStarBrandName = optionsMorningStarBrandName !== null ? optionsMorningStarBrandName.sort((a, b) => a.localeCompare(b)) : '';
			optionsMorningStarCategory = optionsMorningStarCategory !== null ? optionsMorningStarCategory.sort((a, b) => a.localeCompare(b)) : '';
			optionsBaseCurrency = optionsBaseCurrency !== null ? optionsBaseCurrency.sort((a, b) => a.localeCompare(b)) : '';

			this.setState({
				screenerMasterData: nextProps.screenerMasterData,
				morningStarCategory: optionsMorningStarCategory,
				morningStarBrandName: optionsMorningStarBrandName,
				baseCurrency: optionsBaseCurrency
			});

		}

		if (nextProps.resetFiltersToNone) {
			let optionsBaseCurrency = nextProps.screenerMasterData && nextProps.screenerMasterData.Columns && nextProps.screenerMasterData.Columns.map(x => x);
			let optionsMorningStarCategory = nextProps.screenerMasterData && nextProps.screenerMasterData.Columns2 && nextProps.screenerMasterData.Columns2.map(x => x);
			let optionsMorningStarBrandName = nextProps.screenerMasterData && nextProps.screenerMasterData.Columns3 && nextProps.screenerMasterData.Columns3.map(x => x);

			if(Array.isArray(optionsMorningStarBrandName)){
				optionsMorningStarBrandName = optionsMorningStarBrandName.sort((a, b) => a.localeCompare(b));
				}else{
				optionsMorningStarBrandName = '';
			}
			if(Array.isArray(optionsMorningStarCategory)){
				optionsMorningStarCategory = optionsMorningStarCategory.sort((a, b) => a.localeCompare(b));
			}else{
				optionsMorningStarCategory = '';
			}
			if(Array.isArray(optionsBaseCurrency)){
				optionsBaseCurrency = optionsBaseCurrency.sort((a, b) => a.localeCompare(b));
			}else{
				optionsBaseCurrency = '';
			}

			this.setState({
				morningStarCategory: optionsMorningStarCategory,
				morningStarBrandName: optionsMorningStarBrandName,
				baseCurrency: optionsBaseCurrency,
				selectedBrandName: 'None',
				selectedBaseCurrency: 'None',
				selectedMorningStarCategory: 'None',
				starElement: 'None',
				brandNameElement: 'None',
				fundCategoryElement: 'None'
			});
		}
	}

	resetScreenerArguments = () => {
		return {
			argsOperator: 'AND',
			conditions: [
				{
					operator: 'like',
					values: [],
					valueOperator: 'OR'
				}
			],
			field: ''
		};
	}

	resetTab = () => {
		this.props.resetTabOnDropDownChange(this.state.resetTab);
	}

	setScreenerFilterCriteria = () => {
		let screenerInput = {
			argsOperator: 'AND',
			arguments: [
				{
					argsOperator: 'AND',
					conditions: [
						{
							operator: 'like',
							values: [],
							valueOperator: 'OR'
						}
					],
					field: 'MSDWSODIssue'
				},
				{
					conditions: [
						{
							operator: 'greaterThanEqualTo',
							values: utilityFunctions.getPerformanceEndDateValidation().toString().split(","),
							valueOperator: 'OR'
						}
					],
					field: 'MSDPerformanceAsOfDate'
				}
			],
			firstRow: 0,
			resultFields: [
				'MSDFundName',
				'MSDPerformanceAsOfDate',
				'MSDLastClose',
				'MFSSymbol',
				'MSDFundFamilyName',
				'MSDWSODIssue',
				'MSDDomicileCountry',
				'MSDMorningstarCategoryName',
				'MSDInceptionDate',
				'MSDBaseCurrencyID',
				'MFSSymbolISIN'
			],
			resultFormat: 'MarketsTopMutualFunds',
			rowCount: 200,
			sortArguments: [
				{
					direction: 'A',
					field: 'MSDFundName'
				}
			]
		};

		let isValidInput = false;

		let screenerArguments = {
			argsOperator: 'AND',
			conditions: [
				{
					operator: 'like',
					values: [],
					valueOperator: 'OR'
				}
			],
			field: ''
		};


		if (this.state.selectedBrandName !== null && this.state.selectedBrandName !== 'None' &&
			this.state.selectedBaseCurrency !== null && this.state.selectedBaseCurrency !== 'None' &&
			this.state.selectedMorningStarCategory !== null && this.state.selectedMorningStarCategory !== 'None'
		) {

			screenerInput = this.setScreenerInputs(screenerInput, 'MSDBrandingName', this.state.selectedBrandName, 1);
			screenerInput.arguments.push(this.setScreenerInputs(screenerArguments, 'MSDBaseCurrencyID', this.state.selectedBaseCurrency, 0));

			screenerArguments = this.resetScreenerArguments(); //resetting screener input
			screenerInput.arguments.push(this.setScreenerInputs(screenerArguments, 'MSDMorningstarCategoryName', this.state.selectedMorningStarCategory));

			isValidInput = true;
		} else if (this.state.selectedBrandName !== null && this.state.selectedBrandName !== 'None' &&
			this.state.selectedBaseCurrency !== null && this.state.selectedBaseCurrency !== 'None') {


			screenerInput = this.setScreenerInputs(screenerInput, 'MSDBrandingName', this.state.selectedBrandName, 1);

			screenerInput.arguments.push(this.setScreenerInputs(screenerArguments, 'MSDBaseCurrencyID', this.state.selectedBaseCurrency, 0));
			isValidInput = true;

		} else if (this.state.selectedBrandName !== null && this.state.selectedBrandName !== 'None' &&
			this.state.selectedMorningStarCategory !== null && this.state.selectedMorningStarCategory !== 'None') {

			screenerInput = this.setScreenerInputs(screenerInput, 'MSDBrandingName', this.state.selectedBrandName, 1);
			screenerInput.arguments.push(this.setScreenerInputs(screenerArguments, 'MSDMorningstarCategoryName', this.state.selectedMorningStarCategory, 0));

			isValidInput = true;

		} else if (this.state.selectedMorningStarCategory !== null && this.state.selectedMorningStarCategory !== 'None'
			&& this.state.selectedBaseCurrency !== null && this.state.selectedBaseCurrency !== 'None') {


			screenerInput = this.setScreenerInputs(screenerInput, 'MSDMorningstarCategoryName', this.state.selectedMorningStarCategory, 1);
			screenerInput.arguments.push(this.setScreenerInputs(screenerArguments, 'MSDBaseCurrencyID', this.state.selectedBaseCurrency, 0));

			isValidInput = true;
		} else if (this.state.selectedMorningStarCategory !== null && this.state.selectedMorningStarCategory !== 'None') {
			screenerInput = this.setScreenerInputs(screenerInput, 'MSDMorningstarCategoryName', this.state.selectedMorningStarCategory, 1);

			isValidInput = true;
		} else if (this.state.selectedBrandName !== null && this.state.selectedBrandName !== 'None') {
			if (this.props.tabType === 'MFSfund' && this.state.selectedBrandName === 'MFS') {
				isValidInput = false;
			} else {
				screenerInput = this.setScreenerInputs(screenerInput, 'MSDBrandingName', this.state.selectedBrandName, 1);
				isValidInput = true;
			}
		} else if (this.state.selectedBaseCurrency !== null && this.state.selectedBaseCurrency !== 'None') {
			screenerInput = this.setScreenerInputs(screenerInput, 'MSDBaseCurrencyID', this.state.selectedBaseCurrency, 1);
			isValidInput = true;
		}
		
		if (isValidInput) {
			this.props.resetScreenerFilters('false');
			this.props.requestScreenerFiltersDataApi(
				SCREENER_TYPES.MutualFund,
				screenerInput,
				'yes'
			);
			if(this.props.updateScreenerFilterArguments) {
				this.props.updateScreenerFilterArguments(screenerInput.arguments);
			}
		} else {
			this.props.resetScreenerFiltersOutput();
			this.props.resetScreenerFilters('None');
		}
	}

	setScreenerInputs = (screenerInputs, fieldName, value, isScreenerInput) => {
		// screenerInput
		if (isScreenerInput) {
			screenerInputs.arguments[0].field = fieldName;
			screenerInputs.arguments[0].conditions[0].values.push(value);
		} else {
			screenerInputs.field = fieldName;
			screenerInputs.conditions[0].values.push(value);
		}
		return screenerInputs;
	}

	cascadeDropdownFromBrandName = (filteredScreenerMasterData) => {
		let optionsMorningStarCategory = {};
		let optionsBaseCurrency = {};
		if (this.state.selectedMorningStarCategory !== "None") {
			const tempFilteredScreenerMasterData = filteredScreenerMasterData.filter(x => x.Columns2 === this.state.selectedMorningStarCategory);
			optionsBaseCurrency = tempFilteredScreenerMasterData.map(x => x.Columns).filter(x => x);
			optionsBaseCurrency = [...new Set(optionsBaseCurrency.map(item => item))];
			optionsBaseCurrency = optionsBaseCurrency !== null ? optionsBaseCurrency.sort((a, b) => a.localeCompare(b)) : '';
		} else {
			optionsBaseCurrency = filteredScreenerMasterData.map(x => x.Columns).filter(x => x);
			optionsBaseCurrency = [...new Set(optionsBaseCurrency.map(item => item))];
			optionsBaseCurrency = optionsBaseCurrency !== null ? optionsBaseCurrency.sort((a, b) => a.localeCompare(b)) : '';
		}
		if (this.state.selectedBaseCurrency !== "None") {
			console.log(this.state.selectedBaseCurrency)
			const tempFilteredScreenerMasterData = filteredScreenerMasterData.filter(x => x.Columns === this.state.selectedBaseCurrency);
			optionsMorningStarCategory = tempFilteredScreenerMasterData.map(x => x.Columns2).filter(x => x);
			optionsMorningStarCategory = [...new Set(optionsMorningStarCategory.map(item => item))];
			optionsMorningStarCategory = optionsMorningStarCategory !== null ? optionsMorningStarCategory.sort((a, b) => a.localeCompare(b)) : '';
		} else {
			optionsMorningStarCategory = filteredScreenerMasterData.map(x => x.Columns2).filter(x => x);
			optionsMorningStarCategory = [...new Set(optionsMorningStarCategory.map(item => item))];
			optionsMorningStarCategory = optionsMorningStarCategory !== null ? optionsMorningStarCategory.sort((a, b) => a.localeCompare(b)) : '';
		}
		return { optionsBaseCurrency: optionsBaseCurrency, optionsMorningStarCategory: optionsMorningStarCategory }
	}

	onSelectedMorningstarBrandName = (values, e) => {
		const value = values.value;
		const { screenerMasterData } = this.props;
		if (this.state.selectedBrandName !== value) {
			let filteredScreenerMasterData = screenerMasterData && screenerMasterData.ColumnMapper && screenerMasterData.ColumnMapper;
			if (value && value !== 'None') {
				filteredScreenerMasterData = filteredScreenerMasterData.filter(x => x.Columns3 === value);
				const { optionsBaseCurrency, optionsMorningStarCategory } = this.cascadeDropdownFromBrandName(filteredScreenerMasterData)
				this.setState({
					morningStarCategory: optionsMorningStarCategory,
					baseCurrency: optionsBaseCurrency,
					selectedBrandName: value, brandNameElement: this.formatStringValues(value, 0),
					resetTab: true
				}, () => {
					(() => this.setScreenerFilterCriteria())();
				});
			} else {
				const { optionsBaseCurrency, optionsMorningStarCategory } = this.cascadeDropdownFromBrandName(filteredScreenerMasterData)
				this.setState({
					morningStarCategory: optionsMorningStarCategory,
					baseCurrency: optionsBaseCurrency,
					selectedBrandName: value, brandNameElement: value,
					resetTab: true
				}, () => {
					(() => this.setScreenerFilterCriteria())();
				});
			}
		}
	}

	cascadeDropdownBaseCurrency = (filteredScreenerMasterData) => {
		let optionsMorningStarBrandName = {};
		let optionsMorningStarCategory = {};

		if (this.state.selectedBrandName !== "None") {
			const tempFilteredScreenerMasterData = filteredScreenerMasterData.filter(x => x.Columns3 === this.state.selectedBrandName);
			optionsMorningStarCategory = tempFilteredScreenerMasterData.map(x => x.Columns2).filter(x => x);;
			optionsMorningStarCategory = [...new Set(optionsMorningStarCategory.map(item => item))];
			optionsMorningStarCategory = optionsMorningStarCategory !== null ? optionsMorningStarCategory.sort((a, b) => a.localeCompare(b)) : '';
		} else {
			optionsMorningStarCategory = filteredScreenerMasterData.map(x => x.Columns2).filter(x => x);
			optionsMorningStarCategory = [...new Set(optionsMorningStarCategory.map(item => item))];
			optionsMorningStarCategory = optionsMorningStarCategory !== null ? optionsMorningStarCategory.sort((a, b) => a.localeCompare(b)) : '';
		}
		if (this.state.selectedMorningStarCategory !== "None") {
			const tempFilteredScreenerMasterData = filteredScreenerMasterData.filter(x => x.Columns2 === this.state.selectedMorningStarCategory);
			optionsMorningStarBrandName = tempFilteredScreenerMasterData.map(x => x.Columns3).filter(x => x);;
			optionsMorningStarBrandName = [...new Set(optionsMorningStarBrandName.map(item => item))];
			optionsMorningStarBrandName = optionsMorningStarBrandName !== null ? optionsMorningStarBrandName.sort((a, b) => a.localeCompare(b)) : '';
		} else {
			optionsMorningStarBrandName = filteredScreenerMasterData.map(x => x.Columns3).filter(x => x);
			optionsMorningStarBrandName = [...new Set(optionsMorningStarBrandName.map(item => item))];
			optionsMorningStarBrandName = optionsMorningStarBrandName !== null ? optionsMorningStarBrandName.sort((a, b) => a.localeCompare(b)) : '';
		}
		return { optionsMorningStarBrandName: optionsMorningStarBrandName, optionsMorningStarCategory: optionsMorningStarCategory }
	}

	onSelectedBaseCurrency = (values, e) => {
		const value = values.value;
		const { screenerMasterData } = this.props;
		if (this.state.selectedBaseCurrency !== value) {
			let filteredScreenerMasterData = screenerMasterData && screenerMasterData.ColumnMapper && screenerMasterData.ColumnMapper;
			if (value && value !== 'None') {
				filteredScreenerMasterData = filteredScreenerMasterData.filter(x => x.Columns === value);
				const { optionsMorningStarBrandName, optionsMorningStarCategory } = this.cascadeDropdownBaseCurrency(filteredScreenerMasterData)
				this.setState({
					morningStarCategory: optionsMorningStarCategory,
					morningStarBrandName: optionsMorningStarBrandName,
					selectedBaseCurrency: value, brandNameElement: this.formatStringValues(value, 2),
					resetTab: true
				}, () => {
					(() => this.setScreenerFilterCriteria())();
				});
			} else {
				const { optionsMorningStarBrandName, optionsMorningStarCategory } = this.cascadeDropdownBaseCurrency(filteredScreenerMasterData)
				this.setState({
					morningStarCategory: optionsMorningStarCategory,
					morningStarBrandName: optionsMorningStarBrandName,
					selectedBaseCurrency: value, brandNameElement: value,
					resetTab: true
				}, () => {
					(() => this.setScreenerFilterCriteria())();
				});
			}
		}
	}

	cascadeDropdownMorningstarCategory = (filteredScreenerMasterData) => {
		let optionsMorningStarBrandName = {}
		let optionsBaseCurrency = {}

		if (this.state.selectedBrandName !== "None") {
			const tempFilteredScreenerMasterData = filteredScreenerMasterData.filter(x => x.Columns3 === this.state.selectedBrandName);
			optionsBaseCurrency = tempFilteredScreenerMasterData.map(x => x.Columns).filter(x => x);
			optionsBaseCurrency = [...new Set(optionsBaseCurrency.map(item => item))];
			optionsBaseCurrency = optionsBaseCurrency !== null ? optionsBaseCurrency.sort((a, b) => a.localeCompare(b)) : '';
		} else {
			optionsBaseCurrency = filteredScreenerMasterData.map(x => x.Columns).filter(x => x);
			optionsBaseCurrency = [...new Set(optionsBaseCurrency.map(item => item))];
			optionsBaseCurrency = optionsBaseCurrency !== null ? optionsBaseCurrency.sort((a, b) => a.localeCompare(b)) : '';
		}
		if (this.state.selectedBaseCurrency !== "None") {
			const tempFilteredScreenerMasterData = filteredScreenerMasterData.filter(x => x.Columns === this.state.selectedBaseCurrency);
			optionsMorningStarBrandName = tempFilteredScreenerMasterData.map(x => x.Columns3).filter(x => x);
			optionsMorningStarBrandName = [...new Set(optionsMorningStarBrandName.map(item => item))];
			optionsMorningStarBrandName = optionsMorningStarBrandName !== null ? optionsMorningStarBrandName.sort((a, b) => a.localeCompare(b)) : '';
		} else {
			optionsMorningStarBrandName = filteredScreenerMasterData.map(x => x.Columns3).filter(x => x);
			optionsMorningStarBrandName = [...new Set(optionsMorningStarBrandName.map(item => item))];
			optionsMorningStarBrandName = optionsMorningStarBrandName !== null ? optionsMorningStarBrandName.sort((a, b) => a.localeCompare(b)) : '';
		}
		return { optionsBaseCurrency: optionsBaseCurrency, optionsMorningStarBrandName: optionsMorningStarBrandName }
	}

	onSelectedMorningstarCategory = (values, e) => {
		const value = values.value;
		const { screenerMasterData } = this.props;
		if (this.state.selectedMorningStarCategory !== value) {
			let filteredScreenerMasterData = screenerMasterData && screenerMasterData.ColumnMapper && screenerMasterData.ColumnMapper
			if (value && value !== 'None') {
				filteredScreenerMasterData = filteredScreenerMasterData.filter(x => x.Columns2 === value);
				const { optionsMorningStarBrandName, optionsBaseCurrency } = this.cascadeDropdownMorningstarCategory(filteredScreenerMasterData)
				this.setState({
					morningStarBrandName: optionsMorningStarBrandName,
					baseCurrency: optionsBaseCurrency,
					selectedMorningStarCategory: value, fundCategoryElement: this.formatStringValues(value, 1),
					resetTab: true
				}, () => {
					(() => this.setScreenerFilterCriteria())();
				});
			} else {
				const { optionsMorningStarBrandName, optionsBaseCurrency } = this.cascadeDropdownMorningstarCategory(filteredScreenerMasterData)
				this.setState({
					morningStarBrandName: optionsMorningStarBrandName,
					baseCurrency: optionsBaseCurrency,
					selectedMorningStarCategory: value, fundCategoryElement: value,
					resetTab: true
				}, () => {
					(() => this.setScreenerFilterCriteria())();
				});
			}
		}
	}

	displayStarsAsSelectedItem = (rating) => {
		switch (rating) {
			case 5: return <div className={'selectedStar'}>  <img src={FiveStarImage} /> </div>;
			case 4: return <div className={'selectedStar'}>  <img src={FourStarImage} /> </div>;
			case 3: return <div className={'selectedStar'}>  <img src={ThreeStarImage} /> </div>;
			case 2: return <div className={'selectedStar'}>  <img src={TwoStarImage} /> </div>;
			case 1: return <div className={'selectedStar'}>  <img src={OneStarImage} /> </div>;
			case 'None': return 'None';
			default: return 'None';
		}
	}


	formatStringValues = (value, type) => {
		if (type === 0) { // fund Family column
			return <div className="fundFamily">   {value} </div>;
		} else if (type === 2) {
			return <div className="fundFamily">   {value} </div>;
		}
		return <div className="categoryName">   {value} </div>;
	}


	render() {
		const DropdownIndicator = props => {
			return (
				components.DropdownIndicator && (
					<components.DropdownIndicator {...props}>
						<i
							className="dropdown"
							aria-hidden="true"
						/>
					</components.DropdownIndicator>
				)
			);
		};

		const customStyles = {
			control: (base, state) => ({
				...base,
				border: '1px solid rgba(34,36,38,.15) !important',
				boxShadow: '0 !important',
				'&:hover': {
					border: '1px solid rgba(34,36,38,.15) !important'
				},
				height: '34px',
				'min-height': '34px'
			}),
			option: (provided, state) => ({
				...provided,
				background: state.isSelected ? '#f4f4f4' : (state.isFocused ? '#f4f4f4' : '#FFF'),
				color: '#333',
				'font-weight': state.isSelected ? 'bold' : 'normal'
			}),
			menu: styles => ({ ...styles, margin: '0px' }),
			noOptionsMessage: styles => ({ ...styles, 'text-align': 'left', color: 'rgba(0,0,0,.4)' })
		}
		const customTheme = {
			borderRadius: 0,
			border: 0,
			colors: {
				primary: '#f4f4f4',
				primary50: '#f4f4f4',
				neutral0: '#FFF',
			},
		}

		const brandNameList = [{ label: 'None', value: 'None' }];
		const morningstarRatingList = [
			{ label: 'None', value: 'None' }
			// ,
			// { label: <img src={FiveStarImage} />, value: 5 },
			// { label: <img src={FourStarImage} />, value: 4 },
			// { label: <img src={ThreeStarImage} />, value: 3 },
			// { label: <img src={TwoStarImage} />, value: 2 },
			// { label: <img src={OneStarImage} />, value: 1 }
		];
		const morningStarCategoryList = [{ label: 'None', value: 'None' }];
		this.state.morningStarBrandName && this.state.morningStarBrandName.map((item, index) => (
			brandNameList.push({ label: item, value: item })
		));
		this.state.morningStarCategory && this.state.morningStarCategory.map((item, index) => (
			morningStarCategoryList.push({ label: item, value: item })
		));
		this.state.baseCurrency && this.state.baseCurrency.map((item, index) => (
			morningstarRatingList.push({ label: item.replace("CU$$$$$", ""), value: item })
		));
		return (<div id="screenerFilters">
			<div>
				<label className="label-style">Brand Name  </label>
				<label className="label-style morningstar-rating"> Morningstar Category </label>
				<label className="label-style morningstar-category"> Base Currency  </label>
			</div>
			<div>
				<div className="label-style">
					<Select
						name="Fund Family"
						className="dropdown-mfs"
						theme={customTheme}
						styles={customStyles}
						isSearchable={true}
						defaultValue={this.state.selectedBrandName}
						value={{ label: this.state.selectedBrandName, value: this.state.selectedBrandName }}
						onChange={this.onSelectedMorningstarBrandName}
						options={brandNameList}
						components={{ DropdownIndicator }}
						noOptionsMessage={() => "No results found."}
						id="ddnFundFamilyName" />
				</div>
				<div className="label-style">
					<Select
						name="Morningstar Category"
						className="dropdown-mfs"
						theme={customTheme}
						styles={customStyles}
						isSearchable={true}
						defaultValue={this.state.selectedMorningStarCategory}
						value={{ label: this.state.selectedMorningStarCategory, value: this.state.selectedMorningStarCategory }}
						onChange={this.onSelectedMorningstarCategory}
						options={morningStarCategoryList}
						components={{ DropdownIndicator }}
						noOptionsMessage={() => "No results found."}
						id="ddnMorningStarCategory" />
				</div>
				<div className="label-style">
					<Select
						name="Domicile Country"
						className="dropdown-mfs"
						theme={customTheme}
						styles={customStyles}
						isSearchable={true}
						defaultValue={this.state.selectedBaseCurrency}
						value={{ label: this.state.selectedBaseCurrency.replace("CU$$$$$", ""), value: this.state.selectedBaseCurrency }}
						onChange={this.onSelectedBaseCurrency}
						options={morningstarRatingList}
						components={{ DropdownIndicator }}
						id="ddnMorningStarRating" />
				</div>
			</div>
		</div>
		);
	}
}

ScreenerFilters.propTypes = {
	requestScreenerFiltersDataApi: PropTypes.func,
	screenerMasterData: PropTypes.Array,
	resetScreenerFilters: PropTypes.func,
	resetFiltersToNone: PropTypes.boolean,
	resetScreenerFiltersOutput: PropTypes.func
};